<template>
  <section class="login-form">
    <!-- <img
      class="logo"
      src="../images/logo.png"
      alt="儿童成长检测"
    > -->
    <!-- logo -->
    <!-- <div class="login-logo">
      <img
        class="login-logo__pic"
        src="../images/title.png"
        alt="儿童成长检测"
      >
    </div> -->
    <!-- form表单 -->
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      class="form"
    >
      <div class="form-title">
        账号登陆
      </div>
      <el-form-item
        prop="phone"
        class="form-item"
      >
        <PhoneInput
          v-model="form.phone"
          :phoneType.sync="form.phoneType"
        />
      </el-form-item>
      <el-form-item
        prop="password"
        class="form-item"
      >
        <el-input
          v-model="form.password"
          placeholder="请输入密码"
          type="password"
          autocomplete="off"
        />
      </el-form-item>
      <el-form-item class="form-item ">
        <div class="form-tool">
          <div>
            <label class="nologin-label">
              <input
                type="checkbox"
                name="noLogin"
                :value="true"
                checked="checked"
              >
              七天内免登陆
            </label>
          </div>
          <div class="forget">
            <a
              class="forget-link primary-c"
              @click="$router.push({name: 'changePassword',query: {phone: form.phone}})"
            >忘记密码？</a>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          :disabled="disabledSubmit"
          class="form-btn submit"
          type="primary"
          round
          :loading="isSubmit"
          @click="submitForm"
        >
          登录
        </el-button>
      </el-form-item>
      <el-form-item class="form-item">
        <div class="form-agree">
          登录代表我已阅读并同意 <a
            href="#"
            class="primary-c"
          >儿科主任医生集团服务协议</a> 和 <a
            href="#"
            class="primary-c"
          >隐私政策</a>
        </div>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import md5 from 'js-md5';
import { orgLogin as login } from '@/api/login';
import { setUserInfo, redirect } from '@/utils/auth';

export default {
  components: {
    PhoneInput: () => import('./phoneInput.vue')
  },
  data() {
    const validphone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(value.replace(/\s/g, ''))) {
        callback(new Error('请输入正确的手机号!'));
      } else {
        callback();
      }
    };
    const validPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空！'));
      } else {
        callback();
      }
    };
    return {
      form: {
        phone: '',
        phoneType: '1',
        password: '',
      },
      rules: {
        phone: [
          { validator: validphone, trigger: 'change' }
        ],
        password: [
          { validator: validPassword, trigger: 'change' }
        ],
      },
      isSubmit: false
    };
  },
  computed: {
    disabledSubmit() {
      return !this.form.phone || !this.form.password || this.isSubmit;
    }
  },
  created() {
    this.form.phone = this.$route.query.phone;
  },
  methods: {
    submitForm() {
      this.isSubmit = true;
      this.$refs.form.validate((valid) => {
        if (!valid) {
          this.isSubmit = false;
          return;
        }
        this.login();
      });
    },
    login() {
      const password = md5(this.form.password);

      const params = {
        loginMethod: 'PASSWORD',
        loginType: 'ORG',
        password,
        phone: this.form.phone.replace(/\s/g, ''),
      };

      login(params).then((res) => {
        if (!res.data) {
          this.$message.warning(res.msg || '数据为空！');
          return;
        }
        localStorage.setItem('phone', this.form.phone);
        this.$message.success(res.msg || '登陆成功');

        // 设置用户信息
        setUserInfo(res.data);

        // 重定向到指定页面
        redirect(1500);
      }, (err) => {
        this.$message.warning(err.msg || '登陆失败!');
      }).finally((res) => {
        this.isSubmit = false;
      });
    }
  }
};
</script>
<style lang="less" scoped>
.login-form{
    box-sizing: border-box;
    width: 500px;
    background: #F5F4F1;
    border-radius: 8px;
    padding: 10px 50px 0;
    z-index: 9;
}
.logo{
  width: 120px;
  margin-left: -20px;
  margin-top: -20px;
}
// logo
.login-logo{
  text-align: center;
  &__pic{
    width: 210px;
  }
}
// 表单
.form {
  /deep/ .el-input__inner{
    border-radius: 40px;
    border: 1px solid #E5E5E5;
    font-weight: normal;
    font-size: 16px;
    color: #000;
  }
  .form-title{
    font-weight: 500;
    font-size: 16px;
    color: #333;
    padding-left: 4px;
    margin-top: 19px;
    margin-bottom: 16px;
  }
  .form-item{
    margin-bottom: 22px;
  }

  .form-btn{
    display: block;
    width: 100%;
    background: linear-gradient(270deg, #47C2C2 2.64%, #78DBD9 100%);
    border:none;
    /deep/ span{
      color: #fff !important;
    }
    &:disabled{
      opacity: 0.5;
    }
  }
  .form-tool{
    display: table;
    width: 100%;
    font-size: 16px;
    color: #333;
    &>*{
      display: table-cell;
      vertical-align: middle;
    }
  }
  .nologin-label{
    user-select:none;
    cursor: pointer;
    &>input{
      appearance: none;
      background: url(../images/select.svg) no-repeat;
      height: 20px;
      width: 20px;
      border-radius: 20px;
      overflow: hidden;
      vertical-align: middle;
      &:checked{
        background-color: lighten(#5FB4BF,15%);
      }
    }
  }
  .forget{
    text-align: right;
    cursor: pointer;
    user-select: none;
  }
  .form-agree{
    white-space: nowrap;
    &>a{
      text-decoration: auto;
    }
  }
}
</style>
