import service from '@/utils/request';

// 机构登陆
export const orgLogin = (params) => service.post('/orgLogin', params);

// 获取短信验证码
export const getSmsCode = (params) => service.post('/getSmsCode', params);

// 修改密码
export const changePassword = (params) => service.post('/changePassword', params);

// 退出
export const loginOut = (params) => service.post('/loginOut', params);
